const listSORData = async ({ token, effectiveYear }, { sorRepo, observer }) => {
  try {
    sorRepo.listSORData(
      {
        token,
        effectiveYear
      },
      {
        success: (response) => {
          const { data, errors } = response.data
          if (errors?.length) {
            observer.error(errors[0]?.message)
          }
          observer.success(data.listSORData)
        },
        failure: (error) => {
          observer.error(error)
        }
      }
    )
  } catch (error) {
    observer.error(error)
  }
}

export default listSORData
