import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createColumnHelper } from '@tanstack/react-table'
import CreatableSelect from 'react-select/creatable'
import { useState } from 'react'

const columnHelper = createColumnHelper()

const AmountCell = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value)

  return (
    <input
      type="number"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={() => onChange(localValue)}
      className="form-control"
    />
  )
}

const getEditorColumns = (elementKeys, formik, add, remove) => {
  const { budgets } = formik.values

  const element1Options = [...new Set(budgets.map((budget) => budget?.element1 || ''))].map((element1) => ({
    value: element1,
    label: element1
  }))

  return [
    columnHelper.accessor('element1', {
      header: () => elementKeys[0],
      cell: ({ row }) => {
        const { original, index } = row
        if (original.id) {
          return original.element1
        }
        return (
          <CreatableSelect
            className="react-select"
            classNamePrefix="select"
            options={element1Options}
            value={element1Options.find((option) => option.value === original.element1)}
            onChange={(option) => {
              formik.setValues({
                budgets: budgets.map((budget, i) => (i === index ? { ...budget, element1: option.value } : budget))
              })
            }}
          />
        )
      }
    }),
    columnHelper.accessor('element2', {
      header: () => elementKeys[1],
      cell: ({ row }) => {
        const { original, index } = row
        if (original.id) {
          return original.element2
        }
        const element2Options = [
          ...new Set(budgets.filter((b) => b.element1 === original.element1).map((b) => b.element2))
        ].map((element2) => ({
          value: element2,
          label: element2
        }))

        return (
          <CreatableSelect
            className="react-select"
            classNamePrefix="select"
            options={element2Options}
            value={element2Options.find((option) => option.value === original.element2)}
            onChange={(option) => {
              formik.setValues({
                budgets: budgets.map((budget, i) => (i === index ? { ...budget, element2: option.value } : budget))
              })
            }}
          />
        )
      }
    }),
    columnHelper.accessor('element3', {
      header: () => elementKeys[2],
      cell: ({ row }) => {
        const { original, index } = row

        if (original.id) {
          return original.element3
        }

        const element3Options = [
          ...new Set(
            budgets
              .filter((b) => b.element1 === original.element1 && b.element2 === original.element2)
              .map((b) => b.element3)
          )
        ].map((element3) => ({
          value: element3,
          label: element3
        }))

        return (
          <CreatableSelect
            className="react-select"
            classNamePrefix="select"
            options={element3Options}
            value={element3Options.find((option) => option.value === original.element3)}
            onChange={(option) => {
              formik.setValues({
                budgets: budgets.map((budget, i) => (i === index ? { ...budget, element3: option.value } : budget))
              })
            }}
          />
        )
      }
    }),
    columnHelper.accessor('element4', {
      header: () => elementKeys[3],
      cell: ({ row }) => {
        const { original, index } = row

        if (original.id) {
          return original.element4
        }

        const element4Options = [
          ...new Set(
            budgets
              .filter(
                (b) =>
                  b.element1 === original.element1 &&
                  b.element2 === original.element2 &&
                  b.element3 === original.element3
              )
              .map((b) => b.element4)
          )
        ].map((element4) => ({
          value: element4,
          label: element4
        }))

        return (
          <CreatableSelect
            className="react-select"
            classNamePrefix="select"
            options={element4Options}
            value={element4Options.find((option) => option.value === original.element4)}
            onChange={(option) => {
              formik.setValues({
                budgets: budgets.map((budget, i) => (i === index ? { ...budget, element4: option.value } : budget))
              })
            }}
          />
        )
      }
    }),
    columnHelper.accessor('isDeleted', {
      header: () => 'Deleted',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            <input type="checkbox" checked={row.original.isDeleted} disabled={true} />
          </div>
        )
      }
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      enableSorting: false
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      enableSorting: false
    }),
    columnHelper.accessor('amount', {
      header: () => 'Budget',
      cell: ({ row }) => {
        const { index, original } = row

        return (
          <AmountCell
            value={original.amount}
            onChange={(value) => {
              formik.setFieldValue(`budgets[${index}].amount`, value)
            }}
          />
        )
      }
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Actions',
      enableSorting: false,
      cell: ({ row, table }) => {
        const isLastRow = row.index === table.getRowModel().rows.length - 1

        return (
          <div className="d-flex justify-content-evenly">
            {isLastRow && (
              <button type="button" onClick={add}>
                <FontAwesomeIcon icon={faPlusCircle} className="plus-icon" color="green" />
              </button>
            )}

            <button type="button" onClick={() => remove(row.index)}>
              <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red" />
            </button>
          </div>
        )
      }
    })
  ]
}

export default getEditorColumns
