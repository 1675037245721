import TimeCards from './TimeCards'
import TimeCardsTable from './TimeCardsTable'
import Roles from 'config/Roles'
import React from 'react'

const CloudSpendCards = ({ timeCards, inputMonth, getUsageStats, budgetsData, selectedToggle }) => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const isProductManager = roles.includes(Roles.PRODUCT_MANAGERS)
  const isPortfolioManager = roles.includes(Roles.PORTFOLIO_MANAGERS)

  const isActionableUser = isPortfolioManager || isProductManager

  return (
    <>
      <h2 className="mt-4">Cloud Spend Cards</h2>
      {isActionableUser ? (
        <TimeCardsTable
          isActionableUser={isActionableUser}
          isProductManager={isProductManager}
          isPortfolioManager={isPortfolioManager}
          inputMonth={inputMonth}
          timeCards={timeCards}
          getUsageStats={getUsageStats}
          budgetsData={budgetsData}
          selectedToggle={selectedToggle}
        />
      ) : (
        <TimeCards timeCards={timeCards} inputMonth={inputMonth} budgetsData={budgetsData} />
      )}
    </>
  )
}

export default CloudSpendCards
