import defaultLogo from 'assets/logo.svg'
import React, { useEffect, useState } from 'react'

const Logo = ({ alt, className, height = 40 }) => {
  const [imageExists, setImageExists] = useState(false)

  useEffect(() => {
    // Path to the image in the public folder
    const publicImagePath = process.env.PUBLIC_URL + '/customer-logo.svg'

    // Attempt to load the image
    const img = new Image()
    img.src = publicImagePath

    // Check for errors when loading the image
    img.onload = () => {
      setImageExists(true)
    }

    img.onerror = () => {
      setImageExists(false)
    }
  }, [])

  // Use the image from the public folder if it exists, otherwise fallback to the assets folder
  const imageUrl = imageExists ? process.env.PUBLIC_URL + '/customer-logo.svg' : defaultLogo

  return <img alt={alt} className={className} src={imageUrl} height={height} />
}

export default Logo
