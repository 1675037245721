import BudgetEntry from './BudgetEntry'
import BusinessUnit from './BusinessUnits/BusinessUnit'
import Departments from './Departments/Departments'
import Portafolios from './Portfolios/Portfolios'
import Products from './Products/Products'
import Totals from './Totals/Totals'
import getAllBudgetsNew from 'Budgets/use-cases/getAllBudgetsNew'
import LoadingSpinner from 'components/common/LoadingSpinner'
import RadioToggleSwitch from 'components/common/RadioToggleSwitch'
import Roles from 'config/Roles'
import AppContext from 'context/AppContext'
import { useContext, useEffect, useState } from 'react'
import './budgets.scss'

const toggleOptions = [
  {
    value: 'monthly',
    label: 'Monthly'
  },
  {
    value: 'annual',
    label: 'Annual'
  }
]

function Budgets() {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [budgets, setBudgets] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedToggle, setSelectedToggle] = useState('monthly')
  const [isMonthly, setIsMonthly] = useState(true)
  const [showToggle, setShowToggle] = useState(false)

  const [totals, setTotals] = useState(null)
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    setShowToggle(selectedYear === new Date().getFullYear())
  }, [selectedYear])

  useEffect(() => {
    getBudgets()
  }, [selectedYear, repoFactory])

  const getBudgets = () => {
    setIsLoading(true)
    getAllBudgetsNew(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        userRole: JSON.parse(localStorage.getItem('roles'))
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          failure: () => {
            setIsLoading(false)
          },
          success: ({ budgets }) => {
            setTotals(budgets)

            if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
              setSelectedBusinessUnit(budgets?.[0])
            } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
              setSelectedDepartment(budgets?.[0]?.departments?.[0])
            } else if (roles.includes(Roles.PRODUCT_MANAGERS)) {
              setSelectedPortfolio(budgets?.[0]?.departments?.[0]?.portfolios?.[0])
            } else {
              setBudgets(budgets)
            }
            setIsLoading(false)
          }
        }
      }
    )
  }

  useEffect(() => {
    setSelectedDepartment(null)
  }, [selectedBusinessUnit])

  useEffect(() => {
    setSelectedPortfolio(null)
  }, [selectedDepartment])

  useEffect(() => {
    setSelectedProduct(null)
  }, [selectedPortfolio])

  return (
    <div id="budgets">
      {showToggle && (
        <RadioToggleSwitch
          toggleSwitchOptions={toggleOptions}
          selectedOption={selectedToggle}
          onChange={(value) => {
            setSelectedToggle(value)
            setIsMonthly(value === 'monthly')
          }}
        />
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {totals && (
            <>
              <Totals budgets={totals} isMonthly={isMonthly} />{' '}
            </>
          )}

          {budgets && (
            <BusinessUnit
              budgets={budgets}
              isMonthly={isMonthly}
              selectedBusinessUnit={selectedBusinessUnit}
              setSelectedBusinessUnit={setSelectedBusinessUnit}
            />
          )}

          {selectedBusinessUnit ? (
            <Departments
              businessUnit={selectedBusinessUnit}
              isMonthly={isMonthly}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
            />
          ) : null}

          {selectedDepartment ? (
            <Portafolios
              department={selectedDepartment}
              isMonthly={isMonthly}
              selectedPortfolio={selectedPortfolio}
              setSelectedPortfolio={setSelectedPortfolio}
            />
          ) : null}

          {selectedPortfolio ? (
            <>
              <Products
                portfolio={selectedPortfolio}
                isMonthly={isMonthly}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </>
          ) : null}

          {selectedProduct && <BudgetEntry selectedBudget={selectedProduct} getBudgets={getBudgets} />}
        </>
      )}
    </div>
  )
}
export default Budgets
