import uploadSor from 'Admin/Configuration/use-cases/upload-sor-use-case'
import csv from 'csvtojson'
import { useFormik } from 'formik'
import { Alert, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useRef, useState } from 'react'

const validate = (values) => {
  const errors = {}
  const { file } = values

  if (!file) {
    errors.file = 'Please select a file to upload.'
  } else {
    if (!file.name.endsWith('.csv')) {
      errors.file = 'Invalid file format. Please upload a CSV file.'
    } else if (file.size > 1024 * 1024) {
      errors.file = 'The file is too large. Maximum allowed size is 1MB.'
    }
  }

  return errors
}

function UploadFile({ repoFactory, setIsLoading, onDataParsed, selectedYear }) {
  const inputRef = useRef()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      file: null
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const uploadFile = async () => {
    await formik.submitForm()
    if (formik.isValid) {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('file', formik.values.file)

      const fileContent = await readFileAsBase64(formik.values.file)

      uploadSor(
        {
          token: localStorage.getItem('authToken'),
          fileName: formik.values.file.name,
          fileContent: fileContent.split(',')[1],
          fileType: formik.values.file.type
        },
        {
          sorRepo: repoFactory.sorRepo(),
          observer: {
            error: (e) => {
              setIsLoading(false)
              setError(error)
            },
            success: () => {
              formik.resetForm()
              inputRef.current.value = ''
              toast.success('File uploaded successfully.')
              setSuccess(true)
              setIsLoading(false)
              setError(null)
            }
          }
        }
      )
    }
  }

  const parseAndEdit = async () => {
    await formik.submitForm()
    if (formik.isValid && formik.values.file) {
      setIsLoading(true)
      try {
        const reader = new FileReader()
        reader.onload = async (event) => {
          try {
            const csvData = await csv().fromString(event.target.result)
            const elementKeys = Object.keys(csvData[0])
            const [element1Name, element2Name, element3Name, element4Name, budgetName] = elementKeys

            const transformedData = csvData.map((row) => {
              return {
                element1: row[element1Name],
                element2: row[element2Name],
                element3: row[element3Name],
                element4: row[element4Name],
                startDate: row.startDate || `${selectedYear}-01-01`,
                endDate: row.endDate || `${selectedYear}-12-31`,
                amount: row[budgetName].replace(/[^\d.-]/g, '')
              }
            })

            onDataParsed(transformedData, elementKeys)
            toast.success('File loaded for editing')
          } catch (err) {
            console.error('Error parsing CSV:', err)
            setError('Failed to parse CSV file')
            toast.error('Failed to parse file')
          }
          setIsLoading(false)
        }
        reader.onerror = () => {
          setError('Failed to read file')
          setIsLoading(false)
          toast.error('Failed to read file')
        }
        reader.readAsText(formik.values.file)
      } catch (err) {
        setError('Failed to process file')
        setIsLoading(false)
        toast.error('Failed to process file')
      }
    }
  }

  return (
    <div>
      {error ? <div className="alert alert-danger">There was an error, please try again.</div> : null}

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>File Upload</Form.Label>
        {success && (
          <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
            The file was uploaded successfully.
          </Alert>
        )}
        <Form.Control
          ref={inputRef}
          type="file"
          accept=".csv"
          onChange={async (e) => {
            const file = e.target.files[0]
            await formik.setFieldValue('file', file)
            await formik.setFieldTouched('file', true, true)
          }}
        />
        <Form.Text className="text-muted">
          Make sure your file adheres to{' '}
          <a target="_blank" href="https://docs.finopscenter.com/v1/docs/ChartBudgetFile">
            standards
          </a>
          .
        </Form.Text>
        {formik.errors.file && formik.touched.file && <div className="text-danger">{formik.errors.file}</div>}
      </Form.Group>
      <div className="d-flex gap-2">
        <button
          className="btn btn-secondary btn-sm"
          onClick={parseAndEdit}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Edit and Upload
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={uploadFile}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Upload File
        </button>
      </div>
    </div>
  )
}

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

export default UploadFile
