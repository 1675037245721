import moment from 'moment'
import { Card } from 'react-bootstrap'
import { useEffect, useState } from 'react'

const PricingTable = ({ vendorDetails }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (vendorDetails) {
      setData(vendorDetails.mapping)
    }
  }, [vendorDetails])

  return (
    <Card>
      <Card.Body>
        <div className="table-responsive">
          <table className="table table-bordered table-hovered">
            <thead>
              <tr>
                <th>Status</th>
                <th>Vendor Discount Rate</th>
                <th>Applied Rate</th>
                <th>Spend Target</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>User View</th>
                <th>Configuration Date</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span className="badge rounded-pill text-bg-success">Current</span>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">{item.discountRate.value}%</div>
                  </td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">{item.appliedRate.value}%</div>
                  </td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">${item.spendTarget.value}</div>
                  </td>
                  <td>{moment(item.discountRate.startDate).format('MM-DD-YYYY')}</td>
                  <td>{moment(item.discountRate.endDate).format('MM-DD-YYYY')}</td>
                  <td>{item.userView}</td>
                  <td>{moment(item.discountRate.createdDate).format('MM-DD-YYYY')}</td>
                  <td>{item.username}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  )
}
export default PricingTable
