import { createColumnHelper } from '@tanstack/react-table'
import { BudgetStatus } from 'Budgets/constants/BudgetStatus'
import Roles from 'config/Roles'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { percentFormatter } from 'formatters/percent_formatter'
import { Link } from 'react-router-dom'
import React from 'react'

const columnHelper = createColumnHelper()

const currentMonth = new Date().getMonth()

export const getCriteria = () => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  if (roles.includes(Roles.FINANCIAL_ADMINS) || roles.includes(Roles.BU_MANAGERS)) {
    return 'element1'
  } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
    return 'element2'
  } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
    return 'element3'
  }
  return 'element4'
}

const getColumns = (elementNames, timeCards) => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const columns = []

  if (roles.includes(Roles.FINANCIAL_ADMINS) || roles.includes(Roles.BU_MANAGERS)) {
    columns.push(
      columnHelper.accessor('elementMap.element1Id', {
        header: () => elementNames.element1
      })
    )
  } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
    columns.push(
      columnHelper.accessor('elementMap.element2Id', {
        header: () => elementNames.element2
      })
    )
  } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
    columns.push(
      columnHelper.accessor('elementMap.element3Id', {
        header: () => elementNames.element3,
        cell: (info) => {
          const { status } = info.row.original
          const isActionRequired = status.some((item) => item !== BudgetStatus.APPROVED)
          return (
            <div className="d-flex justify-content-between align-items-center fw-medium" style={{ minWidth: '170px' }}>
              {info.getValue()}
              {isActionRequired && (
                <Link to="/budgets" className="badge rounded-pill text-bg-dark m-0 px-3 me-2 text-decoration-none">
                  Budget Task
                </Link>
              )}
            </div>
          )
        }
      })
    )
  } else {
    columns.push(
      columnHelper.accessor('elementMap.element4Id', {
        header: () => elementNames.element4,
        cell: (info) => {
          const { status } = info.row.original
          const isActionRequired = status.some((item) => item !== BudgetStatus.APPROVED)
          return (
            <div className="d-flex justify-content-between align-items-center fw-medium" style={{ minWidth: '170px' }}>
              {info.getValue()}
              {isActionRequired && (
                <Link to="/budgets" className="badge rounded-pill text-bg-dark m-0 px-3 me-2 text-decoration-none">
                  Budget Task
                </Link>
              )}
            </div>
          )
        }
      })
    )
  }

  return columns.concat([
    columnHelper.display({
      header: 'Current Period',
      cell: (props) => {
        const { monthToDateUsage, elementMap } = props.row.original
        const criteriaName = getCriteria()

        if (!timeCards || timeCards.length === 0) {
          return dollarPriceFormatter(monthToDateUsage)
        }

        const filterTimeCard = (timeCard) => {
          const { element1Id, element2Id, element3Id, element4Id } = timeCard.elementMap
          const { element1Id: e1, element2Id: e2, element3Id: e3, element4Id: e4 } = elementMap

          switch (criteriaName) {
            case 'element1':
              return element1Id === e1
            case 'element2':
              return element1Id === e1 && element2Id === e2
            case 'element3':
              return element1Id === e1 && element2Id === e2 && element3Id === e3
            default:
              return element1Id === e1 && element2Id === e2 && element3Id === e3 && element4Id === e4
          }
        }

        const total = timeCards.filter(filterTimeCard).reduce((acc, timeCard) => acc + timeCard.total, 0)

        return dollarPriceFormatter(monthToDateUsage - total)
      }
    }),
    columnHelper.accessor('monthToDateUsage', {
      header: () => 'Current Month',
      cell: (info) => {
        return dollarPriceFormatter(info.getValue())
      }
    }),

    columnHelper.display({
      id: 'availableBudgetCurrentMonth',
      header: () => <>Available Budget Current Month</>,
      cell: (props) => {
        const { budgets, monthToDateUsage } = props.row.original

        let approvedBudget = 0
        budgets.forEach((budget) => {
          approvedBudget += budget[`_${currentMonth}`] || 0
        })

        const value = approvedBudget - monthToDateUsage

        return <div className={value < 0 ? 'red' : 'green'}>{dollarPriceFormatter(value)}</div>
      }
    }),

    columnHelper.accessor('mom', {
      header: () => 'Current Trend',
      cell: (info) => {
        const value = info.getValue()

        return <div className={value === null ? '' : value < 0 ? 'red' : 'green'}>{percentFormatter(value)}</div>
      }
    }),
    columnHelper.accessor('annual', {
      header: () => 'Annual',
      cell: (info) => dollarPriceFormatter(info.getValue())
    })
  ])
}

export default getColumns
