import inviteUserUseCase from '../use-cases/invite-user-use-case'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Roles from 'config/Roles'
import RolesMap from 'config/RolesMap'
import { useFormik } from 'formik'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'This field is required'
  } else if (values.username.includes(' ')) {
    errors.username = 'Username cannot contain spaces'
  } else if (values.username.length < 3) {
    errors.username = 'Username must be at least 3 characters'
  } else if (values.username.length > 20) {
    errors.username = 'Username must be less than 20 characters'
  } else if (!/^[a-zA-Z0-9_.\-]*$/.test(values.username)) {
    errors.username = 'Username can only contain letters, numbers, underscores, hyphens, and periods'
  }

  if (!values.email) {
    errors.email = 'This field is required'
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = 'Please enter a valid email'
  }

  if (!values.role) {
    errors.role = 'This field is required'
  }
  return errors
}

const InviteUser = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const roleOptions = Object.values(Roles).map((role) => ({
    label: RolesMap[role],
    value: role
  }))

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      role: null
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const handleSubmit = async () => {
    await formik.submitForm()
    if (formik.isValid) {
      setIsLoading(true)
      inviteUserUseCase(formik.values, {
        userRepo: props.repoFactory.userRepo(),
        observer: {
          success: () => {
            setIsLoading(false)
            props.getAllUsers()
            toast.success('User invited successfully', {
              hideProgressBar: true
            })
            formik.resetForm()
          },
          failure: (error) => {
            toast.error('Failed to invite user', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      })
    }
  }

  return (
    <div className="mb-4">
      {isLoading && <LoadingSpinner />}

      <h2>Invite User</h2>
      <Card className="mb-4">
        <Card.Body>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="username" className="text-uppercase">
                Username
              </label>
              <input
                id="username"
                type="text"
                name="username"
                className="form-control"
                placeholder="Enter an username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('username', true)}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="text-error">{formik.errors.username}</div>
              ) : null}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="email" className="text-uppercase">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                className="form-control"
                placeholder="Enter an email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('email', true)}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-error">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="role" className="text-uppercase">
                Role
              </label>
              <Select
                id="role"
                placeholder="Select a role"
                options={roleOptions}
                value={roleOptions.find((option) => option.value === formik.values.role)}
                onChange={(option) => formik.setFieldValue('role', option.value || null)}
                className="react-select"
                classNamePrefix="select"
                onBlur={() => formik.setFieldTouched('role', true)}
              />
              {formik.touched.role && formik.errors.role ? (
                <div className="text-error">{formik.errors.role}</div>
              ) : null}
            </div>
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-primary btn-sm" onClick={handleSubmit}>
              Invite User
            </button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default InviteUser
