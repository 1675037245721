import Roles from 'config/Roles'

const RolesMap = {
  [Roles.FINANCIAL_ADMINS]: 'CCOE',
  [Roles.BU_MANAGERS]: 'BU Manager',
  [Roles.DEPARTMENT_MANAGERS]: 'Department Manager',
  [Roles.PORTFOLIO_MANAGERS]: 'Portfolio Manager',
  [Roles.PRODUCT_MANAGERS]: 'Product Manager',
  [Roles.VENDOR_MANAGERS]: 'Vendor Manager'
}

export default RolesMap
