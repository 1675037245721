const getQuickSightURL = ({ username, dashboardId }, { observer, biRepo }) => {
  biRepo.getQuickSightURL(
    { username, dashboardId },
    {
      success: (response) => {
        if (response.getQuickSightDashboardUrl.error) {
          observer.errorGettingUrl()
          return
        }
        observer.receiveQuickSightUrl(response.getQuickSightDashboardUrl.data)
      },
      failure: () => {
        observer.errorGettingUrl()
      }
    }
  )
}

export default getQuickSightURL
