import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import Select from 'react-select'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect } from 'react'

const validate = (values) => {
  const errors = {}

  if (values.enableAmazonQTopics) {
    values.topicList.forEach((topic, index) => {
      errors[index] = {}
      if (!topic.value) {
        errors[index].value = ERROR_MESSAGES.REQUIRED
      }
      if (!topic.name) {
        errors[index].name = ERROR_MESSAGES.REQUIRED
      }
      if (topic.accessTo && topic.accessTo.length === 0) {
        errors[index].accessTo = ERROR_MESSAGES.REQUIRED
      }
    })
  }

  return Object.values(errors).filter((error) => Object.keys(error).length > 0).length > 0 ? errors : {}
}

const getTopicFields = () => ({
  value: '',
  name: '',
  isPublished: true,
  accessTo: [],
  type: 'Topic',
  isSaved: false,
  isEditable: true
})

const QForm = ({ formEl, data, roleOptions }) => {
  const formik = useFormik({
    initialValues: {
      enableAmazonQTopics: true,
      topicList: []
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formEl, formik])

  useEffect(() => {
    const topicList = data || []
    formik.setFieldValue(
      'topicList',
      topicList.length > 0 ? topicList.map((item) => ({ ...item, isSaved: true })) : [{ ...getTopicFields() }]
    )
  }, [data])

  const add = () => {
    formik.setFieldValue('topicList', [...formik.values.topicList, getTopicFields()])
  }

  const remove = (index) => {
    const topicList = [...formik.values.topicList]
    topicList.splice(index, 1)
    formik.setFieldValue('topicList', topicList)
  }

  const { touched, errors } = formik

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="mb-4">
        <div className="form-check">
          <input
            id="enableAmazonQTopics"
            name="enableAmazonQTopics"
            className="form-check-input"
            type="checkbox"
            disabled
            onChange={(e) => {
              formik.setFieldValue('enableAmazonQTopics', e.target.checked)
            }}
            checked={formik.values.enableAmazonQTopics}
          />
          <label className="form-check-label" htmlFor="enableAmazonQTopics">
            Enable Amazon Q Topics
          </label>
        </div>
      </div>

      {formik.values.enableAmazonQTopics && (
        <table className="table table-bordered table-form">
          <thead>
            <tr>
              <th>Asset ID</th>
              <th>Name</th>
              <th>Publish</th>
              <th>Roles</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.topicList.map((topic, index) => (
              <tr key={`topic-${index}`}>
                <td>
                  <input
                    disabled={topic.isEditable === false}
                    name={`topicList[${index}].value`}
                    onChange={formik.handleChange}
                    placeholder="Topic ID"
                    className={`form-control ${
                      touched.topicList?.[index]?.value && errors[index]?.value ? 'has-error' : ''
                    }`}
                    value={topic.value}
                  />
                  {touched.topicList?.[index]?.value && errors[index]?.value && (
                    <div className="text-error">{errors[index].value}</div>
                  )}
                </td>
                <td>
                  <input
                    disabled={topic.isEditable === false}
                    name={`topicList[${index}].name`}
                    onChange={formik.handleChange}
                    placeholder="Topic Name"
                    className={`form-control ${
                      touched.topicList?.[index]?.name && errors[index]?.name ? 'has-error' : ''
                    }`}
                    value={topic.name}
                  />
                  {touched.topicList?.[index]?.name && errors[index]?.name && (
                    <div className="text-error">{errors[index].name}</div>
                  )}
                </td>
                <td className="short-fields">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => {
                      formik.setFieldValue(`topicList[${index}].isPublished`, e.target.checked)
                    }}
                    checked={topic.isPublished}
                  />
                </td>
                <td className="role-fields">
                  <Select
                    name={`topicList[${index}].accessTo`}
                    classNamePrefix="select"
                    className={`${touched.topicList?.[index]?.accessTo && errors[index]?.accessTo ? 'has-error' : ''}`}
                    options={roleOptions}
                    onChange={(option) => {
                      formik.setFieldValue(
                        `topicList[${index}].accessTo`,
                        option?.map((item) => item.value)
                      )
                    }}
                    value={roleOptions.filter((option) => topic.accessTo.includes(option.value))}
                    isMulti
                  />
                  {touched.topicList?.[index]?.accessTo && errors[index]?.accessTo && (
                    <div className="text-error">{errors[index].accessTo}</div>
                  )}
                </td>
                <td className="short-fields text-center">
                  {formik.values.topicList.length - 1 === index && (
                    <button type="button" onClick={add} className="me-2">
                      <FontAwesomeIcon icon={faPlusCircle} className="add-more-icon" color="green" />
                    </button>
                  )}
                  {topic.isEditable !== false && (
                    <button type="button" onClick={() => remove(index)}>
                      <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </form>
  )
}

export default QForm
