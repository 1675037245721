const getDashboardListAdmin = ({ token }, { observer, biRepo }) => {
  biRepo.getDashboardListAdmin(
    { token },
    {
      success: (response) => {
        if (response.getDashboardListAdmin.error) {
          observer.errorWhileGettingDashboardList()
          return
        }
        observer.receiveDashboardList(response.getDashboardListAdmin.data)
      },
      failure: (e) => {
        observer.errorWhileGettingDashboardList()
      }
    }
  )
}

export default getDashboardListAdmin
