import { ToggleOptions } from '../constants/ToogleOptions'
import RadioToggleSwitch from 'components/common/RadioToggleSwitch'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import React from 'react'

const MonthlyUsage = ({ usageStats, selectedToggle, updateToggle, timeCardTotals, showApprovedAndOpenSpend }) => {
  return (
    <>
      <RadioToggleSwitch toggleSwitchOptions={ToggleOptions} selectedOption={selectedToggle} onChange={updateToggle} />
      <Card className="monthly-usage shadow-sm mb-3">
        <Card.Body className="d-flex justify-content-between">
          <div>
            <Card.Text className="title text-uppercase fw-semibold">Total</Card.Text>
            <Card.Text className="title">{dollarPriceFormatter(usageStats.monthToDateUsage)}</Card.Text>
          </div>
          <div className="usage">
            <Card.Text className="text-uppercase fw-semibold">Approved</Card.Text>
            <Card.Text>{dollarPriceFormatter(timeCardTotals.approved)}</Card.Text>
            <Card.Text>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Includes current week charges.</Tooltip>}>
                {({ ...triggerHandler }) => (
                  <span {...triggerHandler} className="text-uppercase fw-semibold">
                    Open
                  </span>
                )}
              </OverlayTrigger>
            </Card.Text>
            <Card.Text>{dollarPriceFormatter(timeCardTotals.remainingOpen)}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default MonthlyUsage
