import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'
import DatePicker from 'react-datepicker'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect } from 'react'

const validate = (values) => {
  let errors = {
    discountRate: {},
    appliedRate: {},
    spendTarget: {}
  }

  if (!values.discountRate.value) {
    errors.discountRate.value = ERROR_MESSAGES.REQUIRED
  } else if (values.discountRate.value && isNaN(values.discountRate.value)) {
    errors.discountRate.value = ERROR_MESSAGES.NUMERIC
  } else if (values.discountRate.value > 100) {
    errors.discountRate.value = 'Discount rate cannot be greater than 100%'
  }

  if (!values.discountRate.startDate) {
    errors.discountRate.startDate = ERROR_MESSAGES.REQUIRED
  }

  if (!values.discountRate.endDate) {
    errors.discountRate.endDate = ERROR_MESSAGES.REQUIRED
  }

  if (!values.appliedRate.value) {
    errors.appliedRate.value = ERROR_MESSAGES.REQUIRED
  } else if (values.appliedRate.value && isNaN(values.appliedRate.value)) {
    errors.appliedRate.value = ERROR_MESSAGES.NUMERIC
  } else if (values.appliedRate.value > 200) {
    errors.appliedRate.value = 'Applied Discount cannot be greater than 200%'
  }

  if (!values.spendTarget.value) {
    errors.spendTarget.value = ERROR_MESSAGES.REQUIRED
  } else if (isNaN(values.spendTarget.value)) {
    errors.spendTarget.value = ERROR_MESSAGES.NUMERIC
  }

  if (
    Object.keys(errors.discountRate).length === 0 &&
    Object.keys(errors.appliedRate).length === 0 &&
    Object.keys(errors.spendTarget).length === 0
  ) {
    errors = {}
  }

  return errors
}

const PricingForm = ({ formEl }) => {
  const formik = useFormik({
    initialValues: {
      discountRate: {
        value: '',
        startDate: null,
        endDate: null
      },
      appliedRate: {
        value: '',
        startDate: null,
        endDate: null
      },
      spendTarget: {
        value: '',
        startDate: null,
        endDate: null
      },
      userView: 'applied'
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  useEffect(() => {
    formEl.current = formik
  }, [formik, formEl])

  const { discountRate, appliedRate, spendTarget, userView } = formik.values

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="row mb-3">
        <div className="col-md-4">
          <label htmlFor="discountRate">Vendor Discount Rate</label>

          <div className="input-group">
            <input
              type="text"
              name="discountRate.value"
              id="discountRate"
              className="form-control"
              placeholder="0"
              value={discountRate?.value}
              onChange={formik.handleChange}
              maxLength={3}
            />
            <span className="input-group-text" id="basic-addon1">
              %
            </span>
          </div>
          {formik.touched.discountRate?.value && formik.errors.discountRate?.value ? (
            <div className="text-error">{formik.errors.discountRate?.value}</div>
          ) : null}
        </div>
        <div className="col-md-4">
          <label htmlFor="appliedRate" className="position-relative">
            Applied Discount
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  Applied Discount is the percentage of List Price that is applied (example: $100 List Price at 95%
                  Applied Discount is $95)
                </Tooltip>
              )}
            >
              <button type="button" className="info-badge">
                <FontAwesomeIcon icon={faExclamation} />
              </button>
            </OverlayTrigger>
          </label>

          <div className="input-group">
            <input
              type="text"
              name="appliedRate.value"
              id="appliedRate"
              placeholder="0"
              className="form-control"
              value={appliedRate?.value}
              onChange={formik.handleChange}
              maxLength={3}
            />
            <span className="input-group-text" id="basic-addon1">
              %
            </span>
          </div>
          {formik.touched.appliedRate?.value && formik.errors.appliedRate?.value ? (
            <div className="text-error">{formik.errors.appliedRate?.value}</div>
          ) : null}
        </div>
        <div className="col-md-4">
          <label htmlFor="spendTarget">Spend Target</label>

          <CurrencyInput
            id="spendTarget"
            prefix="$"
            name="total"
            placeholder="$0.00"
            value={spendTarget.value}
            decimalsLimit={2}
            onValueChange={(value) => formik.setFieldValue('spendTarget.value', value)}
            className="form-control"
          />
          {formik.touched.spendTarget?.value && formik.errors.spendTarget?.value ? (
            <div className="text-error">{formik.errors.spendTarget?.value}</div>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-center gap-3">
        <div>
          <label htmlFor="appliedRate.startDate" className="d-block">
            Start Date
          </label>

          <DatePicker
            id="appliedRate.startDate"
            showIcon
            className="form-control"
            selected={discountRate.startDate ? new Date(discountRate.startDate) : ''}
            onChange={(date) => {
              formik.setFieldValue('discountRate.startDate', date)
              formik.setFieldValue('appliedRate.startDate', date)
              formik.setFieldValue('spendTarget.startDate', date)
            }}
            minDate={moment().add(1, 'days').toDate()}
            maxDate={moment().endOf('year').toDate()}
          />
          {formik.touched.discountRate?.startDate && formik.errors.discountRate?.startDate ? (
            <div className="text-error">{formik.errors.discountRate?.startDate}</div>
          ) : null}
        </div>
        <div>
          <label htmlFor="appliedRate.endDate" className="d-block">
            End Date
          </label>

          <DatePicker
            id="appliedRate.endDate"
            showIcon
            className="form-control"
            selected={discountRate.endDate ? new Date(discountRate.endDate) : ''}
            onChange={(date) => {
              formik.setFieldValue('discountRate.endDate', date)
              formik.setFieldValue('appliedRate.endDate', date)
              formik.setFieldValue('spendTarget.endDate', date)
            }}
            minDate={moment(discountRate.startDate).add(1, 'days').toDate()}
            maxDate={moment().endOf('year').toDate()}
            disabled={!discountRate.startDate}
          />
          {formik.touched.discountRate?.endDate && formik.errors.discountRate?.endDate ? (
            <div className="text-error">{formik.errors.discountRate?.endDate}</div>
          ) : null}
        </div>
      </div>

      <br />
      <div className="mb-3">
        <div className="d-flex justify-content-center gap-4">
          <label>User View</label>

          <div className="d-flex gap-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="userView"
                id="list"
                checked={userView === 'list'}
                onChange={() => formik.setFieldValue('userView', 'list')}
              />
              <label className="form-check-label" htmlFor="list">
                List
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="userView"
                id="applied"
                checked={userView === 'applied'}
                onChange={() => formik.setFieldValue('userView', 'applied')}
              />
              <label className="form-check-label" htmlFor="applied">
                Applied
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
export default PricingForm
