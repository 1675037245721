import AllAccountMappingDetails from './AllAccountMappingDetails'
import React from 'react'

const AccountMapperNew = (props) => {
  return (
    <div className="account-mapper">
      <AllAccountMappingDetails {...props} />
    </div>
  )
}

export default AccountMapperNew
