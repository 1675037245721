import getCreditMappingByElement from '../use-cases/getCreditMappingByElement'
import AppContext from 'context/AppContext'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import moment from 'moment/moment'
import { Card } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'

const CreditInfo = ({ selectedFilters }) => {
  const { repoFactory, lastRunTime, selectedYear } = useContext(AppContext)
  const [creditInfo, setCreditInfo] = useState(null)

  useEffect(() => {
    if (selectedFilters) {
      const { element1, element2, element3, element4 } = selectedFilters
      if (element1 && element2 && element3 && element4) {
        getCreditMappingByElement(
          {
            year: selectedYear,
            token: localStorage.getItem('authToken'),
            element1Id: element1,
            element2Id: element2,
            element3Id: element3,
            element4Id: element4
          },
          {
            creditRepo: repoFactory.creditRepo(),
            observer: {
              success: (data) => {
                if (data.length > 0) {
                  setCreditInfo(data[0])
                } else setCreditInfo(null)
              },
              failure: (error) => {
                console.log(error.message)
              }
            }
          }
        )
      }
    }
  }, [selectedYear, repoFactory, selectedFilters])

  return (
    <>
      {creditInfo && (
        <Card className="mb-3">
          <Card.Body>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Credit info</Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            Credit <strong>{dollarPriceFormatter(creditInfo.amount - creditInfo.usedCredit)}</strong>{' '}
                            Balance as of {moment.utc(lastRunTime).local().format('MM/DD/yyyy hh:mm A')}
                          </td>
                          <td>
                            {dollarPriceFormatter(creditInfo.amount)}{' '}
                            {moment(creditInfo.startDate).format('MM-DD-YYYY')} -{' '}
                            {moment(creditInfo.endDate).format('MM-DD-YYYY')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
        </Card>
      )}
    </>
  )
}
export default CreditInfo
