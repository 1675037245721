import getVarianceReportColumns from './VarianceReportColumns'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable
} from '@tanstack/react-table'
import useElementNames from 'hooks/useElementNames'
import { isCurrentMonth, isFutureMonth } from 'utils/reports-helper'
import UserLevelMap from 'utils/user-level-map'
import React, { useEffect, useState } from 'react'
import './VarianceReport.scss'

const VarianceReportNew = ({ budgetsData, year }) => {
  const elementNames = useElementNames()
  const [budgets, setBudgets] = useState([])
  const [selectedYear, setSelectedYear] = useState(year)

  const userRole = JSON.parse(localStorage.getItem('roles'))
  const userAccess = UserLevelMap[userRole]

  const columns = [
    userAccess === 1 ? 'businessUnit' : undefined,
    userAccess <= 2 ? 'deparment' : undefined,
    userAccess <= 3 ? 'portafolio' : undefined,
    'product'
  ].filter(Boolean)

  const [grouping] = useState([...columns.slice(0, -1)])
  const [columnPinning] = useState({
    left: [...columns]
  })

  const table = useReactTable({
    data: budgets,
    columns: getVarianceReportColumns(selectedYear, budgets, columns, elementNames),
    state: {
      grouping,
      columnPinning
    },
    aggregationFns: {
      varianceAggregation: (columnId, leafRows, childRows) => {
        const monthData = { actual: 0, budget: 0, variance: 0, y2d: 0 }

        childRows.forEach((row) => {
          const month = row.original.months[`_${columnId}`]
          monthData.actual += month.actual
          monthData.budget += month.budget
          monthData.variance += month.variance
          monthData.y2d += month.y2d
        })

        return monthData
      }
    },
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    const items = budgetsData.map((budget) => {
      const { actuals, budgets } = budget
      const months = {}

      let y2d = 0
      for (const [key, value] of Object.entries(actuals)) {
        const budget = budgets?.[key] || 0
        const actual = value?.cost || 0
        const variance = budget - actual
        y2d += variance
        months[key] = {
          actual,
          budget,
          variance,
          y2d
        }
      }
      return {
        businessUnit: budget.element1,
        deparment: budget.element2,
        portafolio: budget.element3,
        product: budget.element4,
        months
      }
    })
    setBudgets([...items])
    setSelectedYear(year)
  }, [budgetsData, year])

  return (
    <div className="table-responsive d-flex mb-3">
      <table className="table table-fixed table-bordered table-hover table-striped">
        <thead>
          {table.getLeftHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className="text-nowrap">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getLeftVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.getIsGrouped() ? (
                    <>
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: {
                            cursor: row.getCanExpand() ? 'pointer' : 'normal'
                          }
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronRight} />
                        )}{' '}
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                        <span className="group-quantity">({row.subRows.length})</span>
                      </button>

                      <div className="variance-row-content-template">
                        <h6>
                          Variance <span className="y2d">(Y2D)</span>
                        </h6>
                        <div className="text-end">
                          <h6>Actual</h6>
                          <h6>Budget</h6>
                        </div>
                      </div>
                    </>
                  ) : cell.getIsAggregated() ? (
                    flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                  ) : cell.getIsPlaceholder() ? null : (
                    <>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      {
                        <div className="variance-row-content-template">
                          <h6>
                            Variance <span className="y2d">(Y2D)</span>
                          </h6>
                          <div className="text-end">
                            <h6>Actual</h6>
                            <h6>Budget</h6>
                          </div>
                        </div>
                      }
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <table className="table table-bordered  table-hover table-striped">
        <thead>
          {table.getCenterHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={
                    isCurrentMonth(selectedYear, header.id)
                      ? 'current-month'
                      : isFutureMonth(selectedYear, header.id)
                      ? 'future-month'
                      : ''
                  }
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getCenterVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={
                    isCurrentMonth(selectedYear, cell.column.id)
                      ? 'current-month'
                      : isFutureMonth(selectedYear, cell.column.id)
                      ? 'future-month'
                      : ''
                  }
                >
                  {cell.getIsAggregated()
                    ? flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                    : cell.getIsPlaceholder()
                    ? null
                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default VarianceReportNew
