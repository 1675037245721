function BIRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getUserMappingAndDashboardList = ({ token, year }, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery($token: String!, $year: Int!) {
                      getDashboardList(token: $token) {
                        data {
                          accountId
                          accountRegion
                          enableCustomThemes
                          defaultThemes {
                            id
                            isEditable
                            type
                          }
                          customThemes {
                            id
                            isEditable
                            type
                          }
                          dashboardList {
                            name
                            value
                            type
                          }
                          topicList {
                            name
                            value
                            type
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                      getUserMappingToBudgetsAndAccounts(token: $token, year: $year) {
                        error {
                          code
                          message
                          statusCode
                        }
                        data {
                          element1
                          element2
                          element3
                          element4
                          accountId
                          accountName
                        }
                      }
                    }`,
          variables: {
            year,
            token
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getDashboardList = ({ token }, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery($token: String!) {
                      getDashboardList(token: $token) {
                        data {
                          accountId
                          accountRegion
                          enableCustomThemes
                          defaultThemes {
                            id
                            isEditable
                            type
                          }
                          customThemes {
                            id
                            isEditable
                            type
                          }
                          dashboardList {
                            accessTo
                            isPublished
                            isEditable
                            name
                            value
                            type
                          }
                          topicList {
                            accessTo
                            isPublished
                            isEditable
                            name
                            value
                            type
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`,
          variables: {
            token
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => {
          failure(error)
        }
      }
    )
  }

  this.getDashboardListAdmin = ({ token }, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query MyQuery($token: String!) {
                      getDashboardListAdmin(token: $token) {
                        data {
                          accountId
                          accountRegion
                          enableCustomThemes
                          defaultThemes {
                            id
                            isEditable
                            type
                          }
                          customThemes {
                            id
                            isEditable
                            type
                          }
                          dashboardList {
                            accessTo
                            isPublished
                            isEditable
                            name
                            value
                            type
                          }
                          topicList {
                            accessTo
                            isPublished
                            isEditable
                            name
                            value
                            type
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`,
          variables: {
            token
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => {
          failure(error)
        }
      }
    )
  }

  this.getQuickSightURL = ({ username, dashboardId }, { success, failure }) => {
    const query = `mutation ($username: String!, $dashboardId: String!) {
                          getQuickSightDashboardUrl(quickSightInput: {username: $username, dashboardId: $dashboardId}) {
                            data {
                              EmbedUrl
                              RequestId
                              Status
                            }
                            error {
                              code
                              message
                              statusCode
                            }
                          }
                        }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            dashboardId
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getQuickSightTopicUrl = ({ username, topicIdList, themeList }, { success, failure }) => {
    const query = `mutation getQuickSightTopicUrl($username: String!, $topicIdList: [String]!, $themeList: [String]) {
                          getQuickSightTopicUrl(quickSightTopicInput: {username: $username, topicIdList: $topicIdList, themeList: $themeList}) {
                            data {
                              EmbedUrl
                              RequestId
                              Status
                            }
                            error {
                              code
                              message
                              statusCode
                            }
                          }
                        }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            username,
            topicIdList,
            themeList
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.saveDashboard = (updateQSList, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `
            mutation MyMutation2($updateQSList: UpdateQSInput!) {
              createOrUpdateDashboard(updateQSList: $updateQSList) {
                data {
                  accountId
                  accountRegion
                  enableCustomThemes
                  defaultThemes {
                    id
                    isEditable
                    type
                  }
                  customThemes {
                    id
                    isEditable
                    type
                  }
                  dashboardList {
                    accessTo
                    isPublished
                    isEditable
                    name
                    value
                    type
                  }
                  topicList {
                    accessTo
                    isPublished
                    isEditable
                    name
                    value
                    type
                  }
                }
                error {
                    code
                    message
                    statusCode
                }
              }
            }
          `,
          variables: {
            updateQSList
          }
        }
      },
      {
        success: (response) => {
          success(response.data)
        },
        failure: (error) => {
          failure(error)
        }
      }
    )
  }
}

export default BIRepo
