import QuickSightEnvironment from './QuickSightEnvironment/QuickSightEnvironment'
import LoadingSpinner from 'components/common/LoadingSpinner'
import AppContext from 'context/AppContext'
import { useContext, useState } from 'react'
import './Configuration.scss'
import { Tab, Tabs } from 'react-bootstrap'

function Configuration() {
  const { repoFactory, selectedYear, isQEnabled } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Tabs className="tabs-section" defaultActiveKey="quicksight">
        <Tab eventKey="quicksight" title="QuickSight Environment">
          <QuickSightEnvironment repoFactory={repoFactory} setIsLoading={setIsLoading} isQEnabled={isQEnabled} />
        </Tab>
      </Tabs>
    </>
  )
}
export default Configuration
