import { Link } from 'react-router-dom'
import React, { memo } from 'react'

function Register() {
  return (
    <div title="Register">
      <Link to="/login">Back to login</Link>
    </div>
  )
}

export default memo(Register)
