import UserAllocation from './UserAllocation'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import React, { useEffect, useState } from 'react'

const UsersWithNoAccess = ({
  usersWithNoBudget,
  isNewUser,
  selectedUserForMapping,
  repoFactory,
  sorElements,
  allocationYear,
  getAllUserElements
}) => {
  const [userForMapping, setUserForMapping] = useState(null)
  const [isSelectedUserNew, setIsSelectedUserNew] = useState(true)

  useEffect(() => {
    if (selectedUserForMapping) {
      setUserForMapping(selectedUserForMapping)
      setIsSelectedUserNew(isNewUser)
    }
  }, [isNewUser, selectedUserForMapping, setUserForMapping, setIsSelectedUserNew, usersWithNoBudget])

  const getUsersOptions = () => {
    const users = usersWithNoBudget.filter((user) => {
      const { Attributes } = user
      const role = Attributes.find((a) => a.Name === 'custom:role').Value
      return role !== 'Vendor_Managers'
    })

    return users.map((user) => ({
      value: user.Username,
      label: user.Username
    }))
  }

  return (
    <div className="simple-card users-with-no-access">
      <h2>User to Scope Mapping</h2>
      <Row>
        <Col md={4}>
          <Select
            classNamePrefix="select"
            placeholder="Select User"
            options={getUsersOptions()}
            onChange={(option) => {
              if (option) {
                const selectedUser = usersWithNoBudget.find((a) => a.Username === option.value)
                setUserForMapping(selectedUser)
                setIsSelectedUserNew(true)
              } else {
                setUserForMapping(null)
                setIsSelectedUserNew(false)
              }
            }}
            isClearable
          />
        </Col>
        <Col md={12} className="mt-3">
          {userForMapping && isNewUser && (
            <UserAllocation
              repoFactory={repoFactory}
              selectedUser={userForMapping}
              isNew={isSelectedUserNew}
              sorElements={sorElements}
              allocationYear={allocationYear}
              getAllUserElements={getAllUserElements}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UsersWithNoAccess
