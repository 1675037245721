import { Tab, Tabs } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Route, useHistory } from 'react-router-dom'
import { getAllowedRoutes } from 'utils'
import React from 'react'
import './admin.scss'
import Routes from 'routes'

const AdminPage = (props) => {
  let history = useHistory()
  const allowedRoutes = getAllowedRoutes(props.children)

  if (props.location.pathname === Routes.ADMIN.PATH) {
    history.replace(allowedRoutes[0].path)
  }

  return (
    <div className="admin-page tabs-section mb-3">
      <Tabs
        activeKey={props.location.pathname}
        onSelect={(e) => {
          history.push(e)
        }}
      >
        {allowedRoutes.map(({ path, title, component: Component }) => {
          return (
            <Tab key={path} eventKey={path} title={<FormattedMessage id={`${title}`} defaultMessage={title} />}>
              <Route {...props} key={path} path={`${path}`}>
                <Component {...props} />
              </Route>
            </Tab>
          )
        })}
      </Tabs>
    </div>
  )
}

export default AdminPage
