import getColumns, { getCriteria } from './BillingColumns'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import useElementNames from 'hooks/useElementNames'
import React, { useEffect, useState } from 'react'

const Billing = ({ usageStats: { billings }, budgetsData, timeCards }) => {
  const [data, setData] = useState([])
  const elementNames = useElementNames()
  const [columns, setColumns] = useState(getColumns(elementNames, billings[0]))
  const [sorting, setSorting] = useState()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  useEffect(() => {
    const criteriaName = getCriteria()
    const budgets = budgetsData?.data || []
    let products = []

    budgets.forEach((budget) => {
      const departments = budget?.departments || []
      departments.forEach((department) => {
        const portfolios = department.portfolios || []
        portfolios.forEach((portfolio) => {
          products = products.concat(portfolio.products || [])
        })
      })
    })

    const items = billings.map((billing) => {
      const id = billing.elementMap[`${criteriaName}Id`]
      const data = products.filter((product) => product[criteriaName] === id)
      return {
        ...billing,
        budgets: data.map((item) => item.budgets),
        status: data.map((item) => item.status)
      }
    })

    setData([...items])
    setColumns(getColumns(elementNames, timeCards))
  }, [billings, timeCards, budgetsData, elementNames])

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Billing
