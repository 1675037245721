import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { getFormattedRow, isFutureMonth, shortMonthNames } from 'utils/reports-helper'

const columnHelper = createColumnHelper()

const getVarianceTemplate = (monthData, year, monthIndex) => {
  if (isFutureMonth(year, monthIndex)) {
    return <div className="variance-row-content"></div>
  }

  return (
    <div className="variance-row-content">
      <h6>
        <span className={monthData.variance < 0 ? 'text-danger' : monthData.variance > 0 ? 'text-success' : ''}>
          {getFormattedRow(Math.abs(monthData.variance), year, monthIndex)}
        </span>{' '}
        <span className={`y2d ${monthData.y2d < 0 ? 'text-danger' : monthData.y2d > 0 ? 'text-success' : ''}`}>
          ({dollarPriceFormatter(Math.abs(monthData.y2d))})
        </span>
      </h6>
      <div className="text-end">
        <h6>{getFormattedRow(monthData.actual, year, monthIndex)}</h6>
        <h6>{getFormattedRow(monthData.budget, year, monthIndex)}</h6>
      </div>
    </div>
  )
}

const getVarianceReportColumns = (year, items, columns, elementNames) => {
  return [
    columnHelper.group({
      header: 'FINANCIAL SCOPE',
      columns: [
        ...(columns.includes('businessUnit')
          ? [
              columnHelper.accessor('businessUnit', {
                header: () => elementNames.element1
              })
            ]
          : []),
        ...(columns.includes('deparment')
          ? [
              columnHelper.accessor('deparment', {
                header: () => elementNames.element2
              })
            ]
          : []),
        ...(columns.includes('portafolio')
          ? [
              columnHelper.accessor('portafolio', {
                header: () => elementNames.element3
              })
            ]
          : []),
        columnHelper.accessor('product', {
          header: () => elementNames.element4
        })
      ]
    }),
    columnHelper.group({
      header: 'PERIOD',
      columns: shortMonthNames.map((month, i) => ({
        accessorKey: `months._${i}`,
        id: `${i}`,
        header: month,
        cell: ({ getValue }) => getVarianceTemplate(getValue(), year, i),
        aggregationFn: 'varianceAggregation',
        aggregatedCell: ({ getValue }) => getVarianceTemplate(getValue(), year, i)
      }))
    })
  ]
}

export default getVarianceReportColumns
