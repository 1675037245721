import { Tab, Tabs } from 'react-bootstrap'
import { Route, useHistory } from 'react-router-dom'
import Routes from 'routes'
import { getAllowedRoutes } from 'utils'
import React, { useEffect } from 'react'

function VendorManagement(props) {
  let history = useHistory()
  const allowedRoutes = getAllowedRoutes(props.children)

  useEffect(() => {
    if (props.location.pathname === Routes.VENDOR_MANAGEMENT.PATH) {
      history.replace(allowedRoutes[0].path)
    }
  }, [])

  return (
    <div className="tabs-section mb-3">
      <Tabs
        activeKey={props.location.pathname}
        onSelect={(e) => {
          history.push(e)
        }}
      >
        {allowedRoutes.map(({ path, title, component: Component }) => {
          return (
            <Tab
              key={path}
              eventKey={path}
              tabClassName={`tab-item ${path === props.location.pathname ? 'active' : ''}`}
              title={title}
            >
              <Route {...props} key={path} path={`${path}`}>
                <Component {...props} />
              </Route>
            </Tab>
          )
        })}
      </Tabs>
    </div>
  )
}
export default VendorManagement
