import { Card } from 'react-bootstrap'

const WelcomePage = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Welcome to FinOps Center</Card.Title>
        <hr />
        <div className="text-center my-5">
          <iframe
            width="800"
            height="450"
            src="https://www.youtube.com/embed/1oDtmcigkpE?si=hiZIKzFkXHuJsa4C"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="mb-3">
          <div className="mb-3">
            <h5>Documentation</h5>
            <a href="https://docs.finopscenter.com/en_US/aws-marketplace-installation" target="_blank">
              https://docs.finopscenter.com/en_US/aws-marketplace-installation
            </a>
          </div>
          <div className="mb-3">
            <h5>Release Notes</h5>
            <a href="https://docs.finopscenter.com/en_US/release-notes" target="_blank">
              https://docs.finopscenter.com/en_US/release-notes
            </a>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
export default WelcomePage
