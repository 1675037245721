import CreditForm from './CreditForm'
import CreditList from './CreditList'
import { Card, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './CreditManagement.scss'
import createCreditMapping from '../use-cases/createCreditMapping'
import getCreditMapping from '../use-cases/getCreditMapping'
import getAllAccounts from 'Admin/AccountMapperNew/use-cases/get-all-accounts'
import LoadingSpinner from 'components/common/LoadingSpinner'
import AppContext from 'context/AppContext'

const CreditManagement = () => {
  const inputRef = useRef()
  const { repoFactory, sorElements, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [creditMappings, setCreditMappings] = useState([])
  const [accounts, setAccounts] = useState([])

  const [show, setShow] = useState(false)

  useEffect(() => {
    getCredit()
    getAccounts()
  }, [selectedYear, repoFactory])

  const getCredit = async () => {
    setIsLoading(true)
    getCreditMapping(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        creditRepo: repoFactory.creditRepo(),
        observer: {
          success: (data) => {
            setCreditMappings(data)
            setIsLoading(false)
          },
          failure: (error) => {
            toast.error('There was an error, please try again.')
            setIsLoading(false)
          }
        }
      }
    )
  }

  const getAccounts = async () => {
    setIsLoading(true)
    getAllAccounts(
      { existingAllocations: [], selectedYear },
      {
        accountMapperRepo: repoFactory.accountMapperRepoNew(),
        observer: {
          accountsReceived: (awsAccounts) => {
            setAccounts(awsAccounts)
            setIsLoading(false)
          },
          errorReceivingAccounts: () => {
            setIsLoading(false)
          }
        }
      }
    )
  }

  const createCredit = async (credit) => {
    const creditForm = inputRef.current
    if (creditForm) {
      setIsLoading(true)
      createCreditMapping(
        {
          token: localStorage.getItem('authToken'),
          creditMapping: { ...credit, year: selectedYear }
        },
        {
          creditRepo: repoFactory.creditRepo(),
          observer: {
            success: () => {
              setShow(false)
              setIsLoading(false)
              creditForm.resetForm()
              getCredit()
              toast.success('Vendor details saved successfully.')
              creditForm.resetForm()
            },
            failure: (error) => {
              toast.error('There was an error, please try again.')
              setIsLoading(false)
            }
          }
        }
      )
    }
  }

  return (
    <div className="credit-management">
      {isLoading && <LoadingSpinner />}
      {sorElements && (
        <button type="button" class="btn btn-primary btn-sm mb-3" onClick={() => setShow(true)}>
          New
        </button>
      )}

      <CreditList creditMappings={creditMappings} />

      <Modal className="credit-modal" show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Body>
          <h2>New Credit</h2>
          <CreditForm formEl={inputRef} sorElements={sorElements} save={createCredit} accounts={accounts} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default CreditManagement
