import ClaimModal from './ClaimModal'
import getResourceColumns from './ResourcesColumns'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import LoadingSpinner from 'components/common/LoadingSpinner'
import Roles from 'config/Roles'
import AppContext from 'context/AppContext'
import { toast } from 'react-toastify'
import claimResource from 'Spaces/use-cases/claimResource'
import listResources from 'Spaces/use-cases/listResources'
import releaseResource from 'Spaces/use-cases/releaseResource'
import { useContext, useEffect, useState } from 'react'

const Resources = ({ filters, account, workloads }) => {
  const { repoFactory, sorElementMapping, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState(null)
  const [resources, setResources] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [sorting, setSorting] = useState([])
  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState({
    open: true,
    claimed: false
  })

  const roles = JSON.parse(localStorage.getItem('roles'))
  const isReadOnly = !roles.some((role) =>
    [Roles.PRODUCT_MANAGERS, Roles.FINANCIAL_ADMINS, Roles.ADMINS].includes(role)
  )

  useEffect(() => {
    setSearch('')
    setStatusFilter({
      open: true,
      claimed: false
    })
    getResources()
  }, [selectedYear, filters, account, repoFactory])

  useEffect(() => {
    const { open, claimed } = statusFilter
    let filtered = [...resources]
    if (open && !claimed) {
      filtered = filtered.filter((elem) => !elem.claimedStatus?.elementMap)
    }

    if (!open && claimed) {
      filtered = filtered.filter((elem) => elem.claimedStatus?.elementMap)
    }

    if (search) {
      filtered = filtered.filter((elem) => {
        const tags = elem.tags?.length ? elem.tags.map((tag) => tag.value).join(' ') : ''
        const reference = elem.claimedStatus?.reference || ''
        return (
          elem.resourceId.toLowerCase().includes(search.toLowerCase()) ||
          elem.productCode.toLowerCase().includes(search.toLowerCase()) ||
          tags.toLowerCase().includes(search.toLowerCase()) ||
          reference.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    setFilteredItems(filtered)
  }, [resources, search, statusFilter])

  useEffect(() => {
    table.toggleAllRowsSelected(false)
  }, [statusFilter])

  const getResources = () => {
    setIsLoading(true)
    listResources(
      {
        searchParams: {
          year: selectedYear,
          accountId: account.accountId,
          elementMap: {
            element1Id: filters.element1,
            element2Id: filters.element2,
            element3Id: filters.element3,
            element4Id: filters.element4
          }
        },
        token: localStorage.getItem('authToken')
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            setResources(response)
            setIsLoading(false)
          },
          failure: (error) => {
            toast.error('Error fetching resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  const table = useReactTable({
    data: filteredItems,
    columns: getResourceColumns(filters, workloads, sorElementMapping, isReadOnly),
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true
  })

  const claim = (resources) => {
    setIsLoading(true)
    claimResource(
      {
        token: localStorage.getItem('authToken'),
        accountId: account.accountId,
        resources
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: () => {
            toast.success('Resources claimed successfully', {
              hideProgressBar: true
            })
            setSelectedRows(null)
            setIsLoading(false)
            table.toggleAllRowsSelected(false)
            getResources()
          },
          failure: () => {
            toast.error('Error claiming resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  const handleClaim = () => {
    const selectedRows = table.getSelectedRowModel().rows

    if (selectedRows.length === 0) {
      toast.warning('Please select at least a resource to claim', {
        hideProgressBar: true
      })
      return
    }

    if (selectedRows.some((row) => row.original.claimedStatus?.elementMap)) {
      toast.warning('Cannot claim already claimed resources', {
        hideProgressBar: true
      })
      return
    }

    if (!selectedRows.every((row) => row.original.claimedStatus?.reference)) {
      toast.warning('Please select Workload to your Claimed Resource', {
        hideProgressBar: true
      })
      return
    }

    const selectedResources = selectedRows.map((row) => ({
      resourceId: row.original.resourceId,
      accountId: row.original.accountId,
      elementMap: {
        element1Id: filters.element1,
        element2Id: filters.element2,
        element3Id: filters.element3,
        element4Id: filters.element4
      },
      reference: row.original.claimedStatus?.reference
    }))

    claim(selectedResources)
  }

  const handleBulkClaim = () => {
    const selectedRows = table.getSelectedRowModel().rows
    if (selectedRows.length === 0) {
      toast.warning('Please select at least a resource to bulk claim', {
        hideProgressBar: true
      })
      return
    }

    if (selectedRows.some((row) => row.original.claimedStatus?.elementMap)) {
      toast.warning('Cannot claim already claimed resources', {
        hideProgressBar: true
      })
      return
    }

    const selectedResources = selectedRows.map((row) => ({
      resourceId: row.original.resourceId,
      accountId: row.original.accountId,
      elementMap: {
        element1Id: filters.element1,
        element2Id: filters.element2,
        element3Id: filters.element3,
        element4Id: filters.element4
      },
      reference: row.original.claimedStatus?.reference
    }))
    setSelectedRows(selectedResources)
  }

  const release = () => {
    const selectedRows = table.getSelectedRowModel().rows

    if (selectedRows.length === 0) {
      toast.warning('Please select at least a resource to release', {
        hideProgressBar: true
      })
      return
    }

    if (selectedRows.some((row) => !row.original.claimedStatus?.elementMap)) {
      toast.warning('Resource is already open', {
        hideProgressBar: true
      })
      return
    }

    const selectedResources = selectedRows.map((row) => ({
      resourceId: row.original.resourceId,
      accountId: row.original.accountId,
      elementMap: {
        element1Id: filters.element1,
        element2Id: filters.element2,
        element3Id: filters.element3,
        element4Id: filters.element4
      },
      id: row.original.claimedStatus?.id,
      reference: row.original.claimedStatus?.reference
    }))
    setIsLoading(true)
    releaseResource(
      {
        token: localStorage.getItem('authToken'),
        accountId: account.accountId,
        resources: selectedResources
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            toast.success('Resources released successfully', {
              hideProgressBar: true
            })
            setIsLoading(false)
            table.toggleAllRowsSelected(false)
            getResources()
          },
          failure: () => {
            toast.error('Error releasing resources', {
              hideProgressBar: true
            })
            setIsLoading(false)
          }
        }
      }
    )
  }

  return (
    <div className="resource-section">
      <div className="mb-3">
        <div className="row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search by Resource, Workload or Tag"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div className="col-8">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="openStatus"
                checked={statusFilter.open}
                onChange={() => setStatusFilter({ ...statusFilter, open: !statusFilter.open })}
              />
              <label className="form-check-label" htmlFor="openStatus">
                Open
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                id="claimStatus"
                type="checkbox"
                checked={statusFilter.claimed}
                onChange={() => setStatusFilter({ ...statusFilter, claimed: !statusFilter.claimed })}
              />
              <label className="form-check-label" htmlFor="claimStatus" checked={statusFilter.claimed}>
                Claimed
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p className="text-sm text-muted mb-2">
        {search || statusFilter.open || statusFilter.claimed
          ? `Showing ${filteredItems.length} from ${resources.length} results`
          : `Showing ${resources.length} results`}
      </p>
      <div className="table-responsive vertical-scroll mb-3">
        {isLoading && <LoadingSpinner />}
        <table className="table table-bordered table-sticky-header">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={`${header.id === 'resourceId' ? 'resource-name-cell' : ''}`}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'w-100 d-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {filteredItems.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center">
                  No results found
                </td>
              </tr>
            )}
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isReadOnly && (
        <div className="d-flex justify-content-center gap-4">
          {statusFilter.open || (!statusFilter.open && !statusFilter.claimed) ? (
            <>
              <button type="button" className="btn btn-primary btn-sm" onClick={handleBulkClaim}>
                Bulk Claim
              </button>
              <button type="button" className="btn btn-primary btn-sm" onClick={handleClaim}>
                Claim
              </button>
            </>
          ) : null}
          {statusFilter.claimed || (!statusFilter.open && !statusFilter.claimed) ? (
            <button type="button" className="btn btn-success btn-sm" onClick={release}>
              Release
            </button>
          ) : null}
        </div>
      )}

      {selectedRows?.length && (
        <ClaimModal selectedRows={selectedRows} setSelectedRows={setSelectedRows} workloads={workloads} claim={claim} />
      )}
    </div>
  )
}
export default Resources
