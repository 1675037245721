import { useFormik } from 'formik'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect } from 'react'

const validate = (values) => {
  const errors = {}

  if (values.enableCustomThemes) {
    if (!values.darkThemeId) {
      errors.darkThemeId = ERROR_MESSAGES.REQUIRED
    }
    if (!values.lightThemeId) {
      errors.lightThemeId = ERROR_MESSAGES.REQUIRED
    }
  }

  return errors
}

const ThemeForm = ({ formEl, defaultThemes, customThemes, isCustomThemeEnabled }) => {
  // Find default theme IDs based on type
  const getDefaultThemeId = (type) => {
    const theme = defaultThemes?.find((theme) => theme.type === type)
    return theme?.id || ''
  }

  const formik = useFormik({
    initialValues: {
      enableCustomThemes: isCustomThemeEnabled || false,
      darkThemeId: customThemes?.find((theme) => theme.type === 'dark')?.id || '',
      lightThemeId: customThemes?.find((theme) => theme.type === 'light')?.id || ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formEl, formik])

  useEffect(() => {
    if (defaultThemes && customThemes) {
      const darkTheme = customThemes.find((theme) => theme.type === 'dark')
      const lightTheme = customThemes.find((theme) => theme.type === 'light')

      formik.setFieldValue('darkThemeId', darkTheme?.id || '')
      formik.setFieldValue('lightThemeId', lightTheme?.id || '')
      formik.setFieldValue('enableCustomThemes', isCustomThemeEnabled || false)
    }
  }, [defaultThemes, customThemes, isCustomThemeEnabled])

  const defaultDarkThemeId = getDefaultThemeId('dark')
  const defaultLightThemeId = getDefaultThemeId('light')

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="mb-3">
        <div className="form-check mb-3">
          <input
            id="enableCustomThemes"
            name="enableCustomThemes"
            className="form-check-input"
            type="checkbox"
            onChange={(e) => {
              formik.setFieldValue('enableCustomThemes', e.target.checked)
              if (!e.target.checked) {
                formik.setFieldValue('darkThemeId', '')
                formik.setFieldValue('lightThemeId', '')
              }
            }}
            checked={formik.values.enableCustomThemes}
          />
          <label className="form-check-label" htmlFor="enableCustomThemes">
            Enable Custom Theme Selection
          </label>
        </div>

        {!formik.values.enableCustomThemes && defaultThemes && (
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>Default Dark Theme ID</label>
                <input disabled className="form-control" value={defaultDarkThemeId} readOnly />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Default Light Theme ID</label>
                <input disabled className="form-control" value={defaultLightThemeId} readOnly />
              </div>
            </div>
          </div>
        )}

        {formik.values.enableCustomThemes && (
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>Custom Dark Theme ID</label>
                <input
                  name="darkThemeId"
                  className={`form-control ${
                    formik.touched.darkThemeId && formik.errors.darkThemeId ? 'has-error' : ''
                  }`}
                  placeholder="Enter Custom Dark Theme ID"
                  value={formik.values.darkThemeId}
                  onChange={formik.handleChange}
                />
                {formik.touched.darkThemeId && formik.errors.darkThemeId && (
                  <div className="text-error">{formik.errors.darkThemeId}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Custom Light Theme ID</label>
                <input
                  name="lightThemeId"
                  className={`form-control ${
                    formik.touched.lightThemeId && formik.errors.lightThemeId ? 'has-error' : ''
                  }`}
                  placeholder="Enter Custom Light Theme ID"
                  value={formik.values.lightThemeId}
                  onChange={formik.handleChange}
                />
                {formik.touched.lightThemeId && formik.errors.lightThemeId && (
                  <div className="text-error">{formik.errors.lightThemeId}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default ThemeForm
