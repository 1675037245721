// DashboardForm.jsx
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Roles from 'config/Roles'
import { useFormik } from 'formik'
import Select from 'react-select'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect, useState } from 'react'

// Remove Q-specific roles, keep only dashboard roles
let roleOptions = Object.keys(Roles).map((key) => ({
  value: Roles[key],
  label: key
}))

let roleDashboardOptions = roleOptions.filter(
  (option) =>
    option.value === Roles.ADMINS || option.value === Roles.FINANCIAL_ADMINS || option.value === Roles.VENDOR_MANAGERS
)

const validate = (values) => {
  const errors = {}
  const { dashboardList } = values

  dashboardList.forEach((dashboard, index) => {
    errors[index] = {}

    let regexUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

    if (!dashboard.value) {
      errors[index].value = ERROR_MESSAGES.REQUIRED
    } else if (!regexUUID.test(dashboard.value)) {
      errors[index].value = ERROR_MESSAGES.INVALID_UUID
    }

    if (!dashboard.name) {
      errors[index].name = ERROR_MESSAGES.REQUIRED
    }

    if (dashboard.accessTo && dashboard.accessTo.length === 0) {
      errors[index].accessTo = ERROR_MESSAGES.REQUIRED
    }
  })

  return Object.values(errors).filter((error) => Object.keys(error).length > 0).length > 0 ? errors : {}
}

const getDashboardFields = () => ({
  value: '',
  name: '',
  isPublished: true,
  accessTo: roleDashboardOptions.map((option) => option.value),
  type: 'Dashboard',
  isSaved: false
})

function DashboardForm({ formEl, data }) {
  const formik = useFormik({
    initialValues: {
      dashboardList: []
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formEl, formik])

  useEffect(() => {
    // Only handle Dashboard type items
    const dashboards = data?.filter((item) => item.type === 'Dashboard') || []
    formik.setFieldValue(
      'dashboardList',
      dashboards.length > 0 ? dashboards.map((item) => ({ ...item, isSaved: true })) : [{ ...getDashboardFields() }]
    )
  }, [data])

  const add = () => {
    formik.setFieldValue('dashboardList', [...formik.values.dashboardList, getDashboardFields()])
  }

  const remove = (index) => {
    const dashboardList = [...formik.values.dashboardList]
    dashboardList.splice(index, 1)
    formik.setFieldValue('dashboardList', dashboardList)
  }

  const { touched, errors } = formik

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <table className="table table-bordered table-form">
        <thead>
          <tr>
            <th>Asset ID</th>
            <th>Name</th>
            <th>Publish</th>
            <th>Roles</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {formik.values.dashboardList.map((dashboard, index) => (
            <tr key={`dashboard-${index}`}>
              <td>
                <input
                  name={`dashboardList[${index}].value`}
                  onChange={formik.handleChange}
                  placeholder="Dashboard ID"
                  className={`form-control ${
                    touched.dashboardList?.[index]?.value && errors[index]?.value ? 'has-error' : ''
                  }`}
                  value={dashboard.value}
                />
                {touched.dashboardList?.[index]?.value && errors[index]?.value ? (
                  <div className="text-error">{errors[index].value}</div>
                ) : null}
              </td>
              <td>
                <input
                  name={`dashboardList[${index}].name`}
                  onChange={formik.handleChange}
                  placeholder="Dashboard Name"
                  className={`form-control ${
                    touched.dashboardList?.[index]?.name && errors[index]?.name ? 'has-error' : ''
                  }`}
                  value={dashboard.name}
                />
                {touched.dashboardList?.[index]?.name && errors[index]?.name ? (
                  <div className="text-error">{errors[index].name}</div>
                ) : null}
              </td>
              <td className="short-fields">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(e) => {
                    formik.setFieldValue(`dashboardList[${index}].isPublished`, e.target.checked)
                  }}
                  checked={dashboard.isPublished}
                />
              </td>
              <td className="role-fields">
                <Select
                  name={`dashboardList[${index}].accessTo`}
                  classNamePrefix="select"
                  className={`${
                    touched.dashboardList?.[index]?.accessTo && errors[index]?.accessTo ? 'has-error' : ''
                  }`}
                  options={roleDashboardOptions}
                  onChange={(option) => {
                    formik.setFieldValue(
                      `dashboardList[${index}].accessTo`,
                      option?.map((item) => item.value)
                    )
                  }}
                  value={
                    dashboard.accessTo
                      ? roleDashboardOptions.filter((option) => dashboard.accessTo.includes(option.value))
                      : []
                  }
                  isMulti
                />
                {touched.dashboardList?.[index]?.accessTo && errors[index]?.accessTo ? (
                  <div className="text-error">{errors[index].accessTo}</div>
                ) : null}
              </td>
              <td className="short-fields text-center">
                {formik.values.dashboardList.length - 1 === index ? (
                  <button type="button" onClick={add} className="me-2">
                    <FontAwesomeIcon icon={faPlusCircle} className="add-more-icon" color="green" />
                  </button>
                ) : null}
                <button type="button" onClick={() => remove(index)}>
                  <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  )
}

export default DashboardForm
