import ProductCard from './ProductCard'
import { Card } from 'react-bootstrap'
import Slider from 'react-slick'
import './Products.scss'
import useElementNames from 'hooks/useElementNames'
import pluralize from 'pluralize'

const slidesToShow = 4

function Products({ portfolio, isMonthly, selectedProduct, setSelectedProduct }) {
  const products = portfolio?.products || []
  const elementNames = useElementNames()

  const settings = {
    ...(products.length < slidesToShow && { className: 'slick-align-left' }),
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }

  return (
    <Card className="products section-gray mb-3">
      <Card.Body>
        <h3 className="text-uppercase">{pluralize(elementNames.element4)}</h3>
        {products.length ? (
          <Slider {...settings}>
            {products.map((product, i) => (
              <ProductCard
                key={`product-card-${i}`}
                portfolioName={portfolio.name}
                product={product}
                isMonthly={isMonthly}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            ))}
          </Slider>
        ) : (
          <div className="alert alert-info mt-3">No products found</div>
        )}
      </Card.Body>
    </Card>
  )
}
export default Products
