import React from 'react'
import './toggle.scss'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ icon, children, onClick }, ref) => {
  return (
    <Button
      className="toggle-label"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <span className="prr">{icon}</span>
      <div className="inline-block prs">{children}</div>
      <FontAwesomeIcon icon={faCaretDown} />
    </Button>
  )
})

export default CustomToggle
