import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import React from 'react'

const ElementList = ({ elements, dropDownStyle, dropDownLabel, elementType, id, onChange }) => {
  return (
    <Dropdown id={id} onSelect={(val) => onChange(JSON.parse(val))} disabled={true}>
      <Dropdown.Toggle variant={dropDownStyle}>{dropDownLabel}</Dropdown.Toggle>

      <Dropdown.Menu>
        {elements.map((el) => {
          el.type = elementType
          return (
            <Dropdown.Item eventKey={JSON.stringify(el)} key={el.id}>
              {el.value}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

ElementList.propTypes = {
  elements: PropTypes.array.isRequired,
  isDependent: PropTypes.bool,
  selectedDependent: PropTypes.string,
  dropDownStyle: PropTypes.string,
  dropDownLabel: PropTypes.string,
  id: PropTypes.string
}

ElementList.defaultProps = {
  elements: [],
  dropDownStyle: 'Primary',
  isDependent: false
}

export default ElementList
